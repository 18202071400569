<template>
  <BModal
    id="confirmationSubmit"
    ref="confirmationSubmit"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
  >
    <div class="text-center my-1">
      <img
        src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        alt="Komerce"
        class="m-auto"
      >
      <div class="font-semibold text-2xl text-black my-1">
        Apakah kamu yakin mau mensubmit?
      </div>
      <p class="text-black">
        Pastikan data sudah benar
      </p>
      <div class="flex justify-center">
        <BButton
          size="md"
          variant="outline-primary"
          class="mr-1"
          style="width: 160.5px"
          @click="$bvModal.hide('confirmationSubmit')"
        >
          Tidak
        </BButton>
        <BButton
          size="md"
          variant="primary"
          style="width: 160.5px"
          block
          @click="$emit('confirm')"
          :disabled="loadingSubmit"
        >
          Ya
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
export default {
  props: {
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
