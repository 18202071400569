<template>
  <BModal
    id="addTalent"
    ref="addTalent"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
    @hidden="talentSelected = []"
  >
    <div class="my-1">
      <div class="font-semibold text-center text-2xl text-black my-1">
        Tambah Talent
      </div>
      <BCol md="12" class="p-0">
        <BFormGroup
          label-class="font-medium text-7 text-black"
          label="Partner"
        >
          <VSelect
            v-model="partnerName"
            label="name"
            disabled
          />
        </BFormGroup>
        <BFormGroup
          label-class="font-medium text-7 text-black"
          label="Talent"
        >
          <VSelect
            v-model="talentSelected"
            :options="listTalent"
            label="talent_name"
            multiple
            :selectable="talent => !talentExist.includes(talent.talent_id)"
          />
        </BFormGroup>
      </BCol>
      <div class="flex justify-center gap-20" style="gap: 20px">
        <BButton
          size="md"
          variant="outline-primary"
          class="w-100"
          style="width: 160.5px"
          @click="$bvModal.hide('addTalent')"
        >
          Batal
        </BButton>
        <BButton
          size="md"
          variant="primary"
          class="w-100"
          block
          @click="addTalent()"
          :disabled="loading"
        >
          <BSpinner variant="light" v-if="loading"></BSpinner>
          Tambahkan
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
import VSelect from 'vue-select'

export default {
  components: {
    VSelect,
  },
  props: {
    talentExist: {
      type: Array,
      default: () => [],
    },
    listTalent: {
      type: Array,
      default: () => [],
    },
    partnerName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      talentSelected: [],
      loading: false,
    }
  },
  methods: {
    addTalent() {
      this.loading = true
      this.$emit('update', this.talentSelected)
      this.loading = false
      this.$bvModal.hide('addTalent')
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
